import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';  // Optional, if you have any global styles
import App from './App'; // Make sure your main component is correctly imported

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);